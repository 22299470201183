@media only screen and (min-width: 1200px)
{
    .logoutBtn{
        height: 0;
        display: none;
    }
}

@media only screen and (max-width: 1200px)
{
    .logoutBtn{
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}