.appShow{
    margin: 1em !important;
}

.appShowdis{
    display: inline-block;
}

.application-selector{
    display: flex;
    align-items: center;
}

#appShowselector{
    min-width: 10em;
}