/* .supportBtn {
    backgroundColor: 'background.default',
          m: 2,
          p: 2,
          position:'absolute',
          top:'3rem',
          right:'0'
} */

@media only screen and (min-width: 768px)
{
    .supportBtn {
        margin: 2rem;
        padding: 2;
        position: absolute;
        top: 3rem;
        right: 0;
    }
}

@media only screen and (max-width: 768px)
{
    .supportBtn {
        margin-top: 3rem;
    }
}